$text-red: #f53535;
$text-black: #000;
$text-white: #fff;

$primary: #00f4d7;
$primary-blue: #3800d6;
$primary-blue-light: #f5f2fd;
$primary-blue-dark: #0d0033;
$white: #ffffff;
$black: #000;
$error: #fb5a5a;
$black80: #333333;
$light-blue: #e8e5fb;
$dark-purple: #110040;
$blue: #2a4bff;
$indigo20T: #{$primary-blue}33;
$blue40T: #{$blue}66;

$divider-red: #fb5a5a;

$disabled: #aaaeb2;

// Fonts
//$font-family: "Source Code Pro", monospace;
//$font-family-title: "Noe Display";
$font-family-title:  "VT323", monospace;
$font-family:  "VT323", monospace;
//$font-family: "DotGothic16", sans-serif;

$light: 350;
$normal: 400;
$medium: 500;
$bold: 500;

$smallFont: 13px;

//$shadow: 0px 2px 8px rgba(399, 144, 177, 0.88);
//$shadow-hover: 0px 2px 8px rgba(399, 144, 177, 0.88);
$shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
$shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);

@import "../styles/theme";

div.poolOverview {
  margin-bottom: 40px;
  width: 100%;
  background: var(--background-element);
  box-shadow: var(--shadow);
  border: 1px solid var(--outline);
  border-radius: 10px;
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 45% 55%;

  .left {
    display: grid;
    grid-template-rows: 40px 24px 22px 10px 20px;
  }

  h4.title {
    width: 100%;
    font-family: $font-family-title;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
    margin-right: 16px;
    grid-row: 1/2;
  }

  .balance {
    grid-row: 3/4;
  }
  .tokens {
    grid-row: 5/6;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div.token {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    & > img {
      margin-right: 4px;
      vertical-align: text-bottom;
    }
  }

  div.right {
    display: grid;
    justify-content: end;

    .margin {
      margin-right: 24px;
    }
    .poolInfo {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-self: end;
    }
    span.label {
      display: block;
      margin-bottom: 4px;
      font-weight: $bold;
    }

    .plus {
      font-weight: $medium;
      color: var(--text-selected);
    }
    .minus {
      font-weight: $medium;
      color: $error;
    }

    .buttons {
      align-self: end;
      display: grid;
      grid-template-columns: 176px 176px;
      column-gap: 16px;
    }
  }
}

@import "../styles/theme.scss";

.infiniteApproval {
  position: relative;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  .label {
    position: relative;
    border-bottom: 1px dotted var(--text);
    font-weight: $bold;
    font-size: 16px;
    letter-spacing: -0.04em;
    margin-left: 16px;

    &:hover {
      cursor: help;
    }
  }
}

@import "../styles/theme";
@import "../styles/viewports";

div.poolsPage {
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  font-size: 16px;
  padding-bottom: 40px;

  .content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

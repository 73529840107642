@import "../styles/theme";
@import "../styles/viewports";

div.slippageField {
  input {
    width: 48px;
    height: 28px;
    border: 1px solid $black;
    border-radius: 8px;
    height: 28px;
    font-size: 90%;
    color: $text-black;
    padding: 2px 4px;
    margin: 4px -4px 4px 4px;
    outline: none !important;
  }

  input:focus {
    outline: 1px solid var(--outline);
  }

  .label {
    font-weight: $bold;
    font-size: 16px;
    letter-spacing: -0.04em;
    margin: auto 16px auto 0;
    @include breakpoint(lg) {
      font-size: 13px;
      margin-right: 0px;
      width: 90px;
    }
    @include breakpoint(sm) {
      font-size: 13px;
      margin-right: 0px;
    }
  }
  .options {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    //justify-content: space-between;
    @include breakpoint(lg) {
      font-size: 12px;
    }
    @include breakpoint(md) {
      font-size: 11px;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .options > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: $normal;
    color: var(--text);
    letter-spacing: -0.04em;
    width: 70px;
    height: 30px;
    margin: 0 2px;
    padding: 4px 10px;
    cursor: pointer;

    &:hover {
      transform: none;
      font-weight: $bold;
    }

    &:focus {
      outline: none;
      border: none;
    }

    &.selected {
      color: var(--text-selected);
      font-weight: $medium;
      border: 1px solid var(--text-selected);
      border-radius: 8px;
    }
    @include breakpoint(lg) {
      font-size: 12px;
      width: 72px;
      height: 28px;
    }
    @include breakpoint(md) {
      font-size: 11px;
      width: 72px;
      height: 28px;
    }
    @include breakpoint(xs) {
      font-size: 11px;
      width: 58px;
      height: 28px;
      padding: 0 2px;
      margin-right: 2px;
    }
  }
}

@import "../styles/theme";
@import "../styles/viewports";

$margin-top: 16px;

div.myShareCard {
  color: var(--text-darker);
  font-size: 16px;

  h4 {
    width: 100%;
    font-family: $font-family-title;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
  }

  div.info {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    row-gap: 8px;
  }

  .poolShare {
    width: 100%;
  }

  .poolShare span {
    color: var(--text-selected);
  }

  div.currency {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(md) {
      width: 200px;
      flex-flow: row wrap;
    }
    .tokenName {
      font-weight: $bold;
      display: block;
    }
  }

  .bold {
    font-weight: $bold;
  }
}

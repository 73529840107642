@import "../styles/theme";

div.toast-container {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 350px;
  padding: 8px;
}

@import "../styles/theme.scss";

div.tokenInput {
  display: flex;
  position: relative;
  align-items: center;

  > span {
    margin-left: 8px;
    flex: 0 0 60px;
  }

  input {
    flex: 1 0 120px;
    min-width: 0;
    height: 32px;
    background: $white;
    border: 0.1px solid var(--outline);
    border-radius: 0;
    margin: 0 8px;
    padding: 0 8px;
    color: $text-black;
    font-size: 16px;
    font-family: $font-family;
    font-style: normal;
    font-weight: $normal;
    &::placeholder {
      color: var(--text-lighter);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $disabled;
      border: none;
    }
  }

  span {
    line-height: 40px;
  }

  img {
    vertical-align: text-bottom;
  }
}

@import "../styles/theme";

button.button {
  border-radius: 4px;
  border: none;
  color: $text-black;
  font-weight: $bold;
  font-size: 16px;
  font-family: $font-family;
  letter-spacing: 0.02em;
  cursor: pointer;
  box-shadow: var(--shadow);
  padding: 1px 6px;

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.primary {
    background: $primary;

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-blue;
      color: $text-white;
      box-shadow: var(--shadow-hover);
    }
  }

  &.secondary {
    background-color: var(--secondary-button);
    color: var(--text-secondary-button);
    font-weight: $normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-blue;
      color: $text-white;
      font-weight: $bold;
      box-shadow: var(--shadow-hover);
    }
  }

  &.ternary {
    color: $white;
    background: $divider-red;
    font-weight: $medium;
    letter-spacing: -0.06em;
  }

  &.cancel {
    background: $white;

    &:enabled:hover,
    &:enabled:focus {
      color: $primary-blue;
    }
  }

  &.large {
    width: 176px;
    height: 40px;
  }

  &.small {
    height: 32px;
  }
}

div.themeChanger {
  button {
    width: 25px;
    height: 25px;
    border: none;
    background: none;
    padding: 0;
    margin-left: 22px;

    color: var(--text);
    opacity: 0.7;
    font-size: 22px;
    transition: transform 0.2s ease;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.3, 1.3);
    }
  }
}
